import {useEffect} from 'react'
import {useAuth, useUser} from "@clerk/clerk-react";
import {getCookies} from './cookies.tsx'

const env = import.meta.env.VITE_APP_ENV;

function AppSignedIn() {
    const {isLoaded, isSignedIn, userId, sessionId} = useAuth();
    const {isLoaded: isUserLoaded, isSignedIn: isUserSignedIn, user} = useUser();

    if (isLoaded && isSignedIn && isUserLoaded && isUserSignedIn) {
        useEffect(() => {
            // NOTE: In prod, clerk uses cookies -- cookies which we can't read from Javascript (directly) so
            // we have to hack around that. In dev, clerk uses local browser storage, which we can read from easily.
	    //
	    // NB: Clerk doesn't have a staging equivalent. They only have dev and prod. This puts us in an awkward
	    // position since we have to choose one for our staging. We've chosen prod so that we can ensure compatibility
	    // before it rolls out to real prod.
            if (env == "prod" || env == "staging") {
                getCookies().then(cookies => {
                    let payload = {
                        userId: userId,
                        sessionId: sessionId,
                        cookies: cookies,
                        user: user,
                    }
                    const js = JSON.stringify(payload)
                    const value = btoa(js);

                    setTimeout(() => {
                        // 👇️ redirects to an external URL
                        window.location.replace('http://localhost:3535/done?p=' + value);
                    }, 1000);
                })
                .catch(error => {
                    console.error(error);
                })
                return;
            } else {
                const jwt = localStorage["clerk-db-jwt"];

                let payload = {
                    userId: userId,
                    sessionId: sessionId,
                    token: jwt,
                    user: user,
                }

                const js = JSON.stringify(payload)
                const value = btoa(js);

                const timeout = setTimeout(() => {
                    // 👇️ redirects to an external URL
                    window.location.replace('http://localhost:3535/done?p=' + value);
                }, 1000);

                return () => clearTimeout(timeout);
            }
        }, []);
    }

    return <>Redirecting...</>
}

export default AppSignedIn;
